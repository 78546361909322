const config = {
	siteTitle: "Gianluca Frongia",
	siteTitleShort: "Gianluca Frongia",
	siteTitleAlt: "Gianluca Frongia",
	siteLogo: "/logos/logo-1024.png",
	siteUrl: "https://gianluca-frongia.dev",
	dateFromFormat: "YYYY-MM-DD",
	pathPrefix: "",
	siteDescription:
		"I'm a front end delevoper from basel creating and designing website for people with great ideas.",
	postDefaultCategoryID: "Tech",
	theme_color: "#f582ae",
	background_color: "#fef6e4",
}

// Make sure pathPrefix is empty if not needed
if (config.pathPrefix === "/") {
	config.pathPrefix = ""
} else {
	// Make sure pathPrefix only contains the first forward slash
	config.pathPrefix = `/${config.pathPrefix.replace(/^\/|\/$/g, "")}`
}

// Make sure siteUrl doesn't have an ending forward slash
if (config.siteUrl.substr(-1) === "/")
	config.siteUrl = config.siteUrl.slice(0, -1)

module.exports = config
