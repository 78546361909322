import React from "react"
import { Helmet } from "react-helmet"
import ThemeStore from "../css/themes"
import config from "../../data/SiteConfig"
import styled, { ThemeProvider } from "styled-components"
import Navigation from "../components/Navigation"
import { Link } from "gatsby"
import Footer from "../components/Footer"
import GlobalStyle from "../css/globalStyle"
import global from "../css/globalVariables"

const Main = styled.main`
	margin: 45px 0 45px 0;
	flex: 1;

	@media screen and (${global.mediaDesktop}) {
		margin: 150px 0 85px 0;
	}
`
const Header = styled.header`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-top: 45px;
`
const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	padding: 0 10px;
`

const Layout = ({ children }) => {
	return (
		<ThemeProvider theme={ThemeStore.theme1}>
			<GlobalStyle />
			<Helmet>
				<title>{config.title}</title>
				<meta name="description" content={config.siteDescription}></meta>
			</Helmet>
			<Wrapper>
				<Header>
					<Link to="/"> Gianluca Frongia </Link>
					<Navigation />
				</Header>
				<Navigation mobile={true} />
				<Main>{children}</Main>
				<Footer />
			</Wrapper>
		</ThemeProvider>
	)
}

export default Layout
