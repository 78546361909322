import { createGlobalStyle } from "styled-components"

const GlobalStyle = createGlobalStyle`
    *,
    ::after,
    ::before,
    html,
    body {
        box-sizing: border-box;
        font-size: 1.15rem;
        margin: 0;
    }

    body {
        font-family: "Helvetica Neue", "Segoe UI", "Helvetica", "Arial", sans-serif;
        font-weight: normal;
        word-wrap: break-word;
        font-kerning: normal;
        transition: background-color 0.1s linear;
        background-color: ${(props) => props.theme.bg};
        color: ${(props) => props.theme.font};
        min-height: 100vh;
        max-width: 900px;
        margin-left: auto;
        margin-right: auto;
    
    }

    a,
    a:link,
    a:hover,
    a:active,
    a:visited {
        font-size: 1.2rem;
        color: ${(props) => props.theme.link};
        text-decoration: none;
    }

    h1,
    h2,
    h3 {
    color:  ${(props) => props.theme.title};
    line-height: 1.3;
    }
    h1 {
    font-size: 1.7rem;
    margin-bottom: 1.5rem
    }

    h2 {
        font-size: 1.5rem;
        margin-bottom: 2rem;
        font-weight: 500;
    }

    h3 {
        font-size: 1.4rem;
        margin-bottom: 1rem;
        font-weight: 500;
    }

    h1:not(:first-child),
    h2:not(:first-child),
    h3:not(:first-child) {
    margin-top: 4.5rem;
    }
    h1:not(:first-child),
    h2:not(:first-child),
    h3:not(:first-child),
    h4:not(:first-child),
    h5:not(:first-child) {
    margin: 0 0 1.5rem;
    }

    h1:not(:first-child),
    h2:not(:first-child),
    h3:not(:first-child) {
    margin-top: 3rem;
    }

    p,
    ul,
    i {
    font-size: 1.1rem;
    margin: 0 0 2rem;
    line-height: 1.6;
    }
    blockquote {
		background: ${(props) => props.theme.bg};
		border-left: 10px solid #ccc;
		margin: 1.5em 10px;
		padding: 0.3em 10px 0em 10px;
	}
`

export default GlobalStyle
