const ThemeStore = {
	theme1: {
		bg: "#004643",
		bgLight: "#001e1d",
		link: "#f9bc60",
		font: "#eaf2f0",
		title: "#fffffe",
	},

	theme2: {
		bg: "#fef6e4",
		bgLight: "#f3d2c1",
		link: "#f582ae",
		font: "#172c66",
		title: "#001858",
	},

	theme3: {
		bg: "#232946",
		bgLight: "#181d31",
		link: "#eebbc3",
		font: "#b8c1ec",
		title: "#fffffe",
	},

	theme4: {
		bg: "#202020",
		bgLight: "#474747",
		link: "#ffa000",
		font: "#d8cac9",
		title: "#fffffe",
	},
}

export default ThemeStore
