import React from "react"
import styled from "styled-components"

const StyledFooter = styled.footer`
	padding-bottom: 45px;
`

const Footer = () => {
	return (
		<StyledFooter>
			<p>Gianluca Frongia © 2020</p>
		</StyledFooter>
	)
}

export default Footer
