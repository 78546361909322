import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import AboutMeIcon from "../assets/icons/about-me.svg"
import ArticlesIcon from "../assets/icons/articles.svg"
import ProjectsIcon from "../assets/icons/projects.svg"
import ContactIcon from "../assets/icons/contact.svg"
import global from "../css/globalVariables"

const NavLink = styled(Link)`
	&& {
		color: ${(props) => props.theme.font};
		display: flex;
		align-items: center;
		flex-direction: column;
		margin: 0px;
		font-size: 1rem;
		&.current-page,
		&:hover {
			color: ${(props) => props.theme.link};
		}

		& svg {
			margin-bottom: 5px;
		}
		@media screen and (${global.mediaDesktop}) {
			margin: 0 7.5px;
		}
	}
`
const NavMobile = styled.nav`
	display: flex;
	justify-content: space-between;
	position: relative;
	padding-top: 30px;
	position: fixed;
	width: 100vw;
	bottom: 0;
	left: 0;
	padding: 10px;
	background-color: ${(props) => props.theme.bg};
	border-top: 2px solid ${(props) => props.theme.link};
	z-index: 10;
	@media screen and (${global.mediaDesktop}) {
		display: none;
	}
`

const NavDesktop = styled.nav`
	display: none;
	@media screen and (${global.mediaDesktop}) {
		display: flex;
	}
`
const Navigation = ({ mobile }) => {
	if (mobile) {
		return (
			<NavMobile>
				<NavLink to="/about-me" activeClassName="current-page">
					<AboutMeIcon />
					About me
				</NavLink>
				<NavLink to="/articles" activeClassName="current-page">
					<ArticlesIcon />
					Articles
				</NavLink>
				<NavLink to="/projects" activeClassName="current-page">
					<ProjectsIcon />
					Projects
				</NavLink>
				<NavLink to="/contact" activeClassName="current-page">
					<ContactIcon />
					Contact
				</NavLink>
			</NavMobile>
		)
	} else {
		return (
			<NavDesktop>
				<NavLink to="/about-me" activeClassName="current-page">
					About me
				</NavLink>
				<NavLink to="/articles" activeClassName="current-page">
					Articles
				</NavLink>
				<NavLink to="/projects" activeClassName="current-page">
					Projects
				</NavLink>
				<NavLink to="/contact" activeClassName="current-page">
					Contact
				</NavLink>
			</NavDesktop>
		)
	}
}

export default Navigation
